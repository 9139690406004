import React, { useEffect, useState, useRef } from "react";
import useSound from "use-sound";
import FrameLayout from "../Common/FrameLayout/FrameLayout";
import { useActiveSubroutine } from "../../Contexts/ActiveSubroutine";

const SubroutineLayout = ({ title, children }) => {
  const [showContent, setShowContent] = useState(false);
  const [show, setShow] = useState(false);
  const { activeSubroutine, setActiveSubroutine } = useActiveSubroutine();
  const [playSound] = useSound("/sfx/readShort1.ogg", { volume: 0.5 });
  const contentRef = useRef(null);
  const windowWidth = useRef(window.innerWidth);
  const isMobile = windowWidth.current < 992;

  useEffect(() => {
    setTimeout(
      () => {
        setShow(true);
        playSound();
      },
      activeSubroutine.fromCLI ? 0 : 500
    );
  }, [activeSubroutine, playSound]);

  useEffect(() => {
    if (show) {
      setTimeout(() => setShowContent(true), 250);
    }
  }, [show]);

  useEffect(() => {
    if (isMobile) {
      var element = contentRef?.current;
      if (element) {
        var headerOffset = 50;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [showContent, isMobile]);

  const onClose = () => {
    setActiveSubroutine({ name: null, fromCLI: false });
  };

  if (!show) return null;

  return (
    <div id="subroutine-container">
      <div className="glitch-init">
        <FrameLayout title={title} showClose onClose={onClose}>
          {showContent && (
            <div className="content glitch-init" ref={contentRef}>
              {children}
            </div>
          )}
        </FrameLayout>
      </div>
    </div>
  );
};

export default SubroutineLayout;
