import React from "react";
import { ResponsiveRadar } from "@nivo/radar";
import SubroutineLayout from "../../SubroutineLayout/SubroutineLayout";
import ImageLoader from "../../Common/ImageLoader/ImageLoader";
import Heartbeat from "../../Heartbeat/Heartbeat";
import AudioPlayer from "../../Common/AudioPlayer/AudioPlayer";
import "./Subject.scss";

const nivoTheme = {
  background: "transparent",
  textColor: "#6473FF",
  fontSize: 11,
  axis: {
    domain: {
      line: {
        stroke: "#6473FF",
        strokeWidth: 1,
      },
    },
    ticks: {
      line: {
        stroke: "#6473FF",
        strokeWidth: 1,
      },
    },
  },
  grid: {
    line: {
      stroke: "#6473FF",
      strokeWidth: 1,
    },
  },
};

const programmerStats = [
  { technology: "JavaScript", level: 90 },
  { technology: "TypeScript", level: 70 },
  { technology: "React", level: 90 },
  { technology: "Node", level: 70 },
  { technology: "MongoDB", level: 80 },
  { technology: "Unity", level: 70 },
  { technology: "Docker", level: 60 },
  { technology: "AWS", level: 40 },
];

const physicalStats = [
  { stat: "STR", level: 50 },
  { stat: "AGI", level: 75 },
  { stat: "DEX", level: 80 },
  { stat: "LUK", level: 60 },
  { stat: "INT", level: 85 },
  { stat: "VIT", level: 50 },
];

const Subject = () => {
  return (
    <SubroutineLayout title="Subroutine: Subject">
      <div className="subject">
        <div className="two-columns">
          <div className="column info-block status">
            <h1>{"///"} Status {"///"}</h1>
            <div className="info">
              <div className="body">
                <ImageLoader imgSrc={"img/body.gif"} imgAlt={"Pablo's body"} />
              </div>
              <div className="heart-container">
                <div className="heart">
                  <Heartbeat />
                </div>
                <div className="margin-t-15">Good</div>
              </div>
            </div>
          </div>
          <div className="column info-block information">
            <h1>{"///"} Extra Information {"///"}</h1>
            <div className="container">
              <div>
                <img src="img/finger.svg" alt="fingerprint" />
              </div>
              <div>
                <div>{">>"} Height: 170cm</div>
                <div>{">>"} Weight: 70kg</div>
                <div>{">>"} Married: No</div>
                <div>{">>"} Children: 0</div>
                <div>{">>"} Blood type: 0+</div>
                <div>{">>"} Diet: Omnivore</div>
                <div>{">>"} Driver licence: Yes</div>
                <div>{">>"} Weakpoints: Knees</div>
                <div>{">>"} Agent assigned: SCP-A732-K9 Codename PANCHA</div>
              </div>
            </div>
          </div>
        </div>
        <div className="stats info-block">
          <div className="two-columns charts">
            <div className="column chart radial">
              <h1>{"///"} Attributes {"///"}</h1>
              <ResponsiveRadar
                data={physicalStats}
                keys={["level"]}
                indexBy="stat"
                maxValue="100"
                margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                curve="linearClosed"
                gridLevels={3}
                gridShape="linear"
                gridLabelOffset={20}
                enableDotLabel={false}
                fillOpacity={0.5}
                animate={false}
                isInteractive={false}
                colors="#6473FF"
                theme={nivoTheme}
                enableDots={true}
                dotSize={5}
              />
            </div>
            <div className="column">
              <h1>{"///"} Skills {"///"}</h1>
              <div className="skills">
                {programmerStats.map((stat) => (
                  <div className="skill">
                    <div className="skill-label">{stat.technology}</div>
                    <div className="bar">
                      <div
                        className="fill"
                        style={{ width: `${stat.level}%` }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="info-block">
          <h1>{"///"} Programmer profile {"///"}</h1>
          <p className="programmer-profile-text">
            The subject appears to have a keen interest in programming,
            particularly in the realm of frontend development. He takes great
            pleasure in meticulously replicating design specifications and
            strive to deliver seamless user experiences. The subject's
            problem-solving approach aligns with the KISS principle, always
            prioritizing simplicity and straightforwardness in his solutions.
          </p>
        </div>
        <div className="info-block languages">
          <h1>{"///"} Languages {"///"}</h1>
          <div className="lang-list">
            <div className="lang">
              <div className="lang-label">{">> "}Spanish</div>
              <div className="lang-level">Native level</div>
              <AudioPlayer
                src="/sfx/speech-sample-ES.mp3"
                id="voice1"
                title="speech-sample-ES.mp3"
              />
            </div>
            <div className="lang">
              <div className="lang-label">{">> "}English</div>
              <div className="lang-level">Intermediate level</div>
              <AudioPlayer
                src="/sfx/speech-sample-EN.mp3"
                id="voice2"
                title="speech-sample-EN.mp3"
              />
            </div>
            <div className="lang">
              <div className="lang-label">{">> "}Japanese</div>
              <div className="lang-level">Meme level</div>
              <AudioPlayer
                src="/sfx/speech-sample-JP.mp3"
                id="voice3"
                title="speech-sample-JP.mp3"
              />
            </div>
          </div>
        </div>
        <div className="info-block hobbies">
          <h1>{"///"} Hobbies {"///"}</h1>
          <p>
            The subject appears to have a diverse range of interests and
            hobbies, which are subject to change over time. While it's
            challenging to document all of these activities, some have remained
            constant, including:
          </p>
          <ul>
            <li>
              <span>{">> "}Reading</span> Our agents conducted a discreet
              inspection of the subject's apartment, paying particular attention
              to his book collection. It appears that the subject has a strong
              fondness for fantasy literature, as evidenced by the presence of
              several notable titles, including The Lord of the Rings, The Name
              of the Wind, Eragon, and Geralt of Rivia, among others.
            </li>
            <li>
              <span>{">> "}Watching anime</span> Through hacking the subject's
              network, we were able to identify a substantial amount of
              anime-related media traffic. Upon further investigation, it became
              evident that the subject is particularly fond of certain series,
              including Evangelion and Steins Gate.
            </li>
            <li>
              <span>{">> "}Playing video games</span> Based on the analysis of
              the subject's Steam profile, it appears that he has a preference
              for graphic adventure, puzzle and horror games. Notable favorites
              within these genres include Alien: Isolation, Fran Bow, The Dig,
              Witcher 3, Myst, and many others.
            </li>
            <li>
              <span>{">> "}Game development</span> The subject appears to have a
              passion for working on small-scale game development projects and
              participating in game jams. To view the specific projects for
              which we have collected information, please execute the "Projects"
              subroutine.
            </li>
            <li>
              <span>{">> "}Sports</span> To our surprise, the subject appears to
              have an interest in at least a couple of sports, specifically
              swimming and mountain biking.
            </li>
            <li>
              <span>{">> "}Board games</span> The subject seems to enjoy playing
              board games with friends and has also been observed accessing an
              online chess site. However, based on our analysis, it appears that
              his skill level in chess may be limited.
            </li>
            <li>
              <span>{">> "}Art</span> Although the subject's artistic abilities
              may not be highly developed, he does appear to have an affinity
              for photography, as demonstrated by his use of a DSLR camera to
              capture images. Additionally, there are indications that the
              subject may have an interest in writing.
            </li>
          </ul>
        </div>
      </div>
    </SubroutineLayout>
  );
};

export default Subject;
