import * as React from "react";

const BackgroundSoundContext = React.createContext();

function BackgroundSoundProvider({ children }) {
  const [playing, setPlaying] = React.useState(false);
  const play = () => {
    if (!playing) setPlaying(true);
  };
  const stop = () => {
    if (playing) setPlaying(false);
  };
  return (
    <BackgroundSoundContext.Provider value={{ play, stop, playing }}>
      {children}
    </BackgroundSoundContext.Provider>
  );
}

function useBackgroundSound() {
  const context = React.useContext(BackgroundSoundContext);
  if (context === undefined) {
    throw new Error(
      "useBackgroundSound must be used within a BackgroundSoundProvider"
    );
  }
  return context;
}

export { BackgroundSoundProvider, useBackgroundSound };
