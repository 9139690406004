import React from 'react'
import './Loading.scss'

const Loading = ({ text, fontSize }) => {
    return (
        <div className="loading" style={{fontSize: `${fontSize || 16}px`}}>
            <span>{text}{' '}<span className="loader"></span></span>
        </div>
    )
}

export default Loading