import React from 'react'
import './Heartbeat.scss'

const Heartbeat = () => {
    return (
        <div className="heartbeat">
            <img src="img/heartbeat.png" alt="heartbeat" />
            <div className="layer" />
            <div className="fade-in" />
            <div className="fade-out" />
        </div>
    )
}

export default Heartbeat;