import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import { useBackgroundSound } from "../../Contexts/BackgroundSound";
import Loading from "../Common/Loading/Loading";
import "./BootAnimation.scss";

const bootAnimation = [
  { slide: 1, name: "memoryTest", timeToNextStep: 1000, memory: "0k" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "156K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "241K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "577K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "6047K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "7514K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "8304K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "9861K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "105756K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "108114K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "114924K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "157135K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "173684K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "193671K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "215617K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "220547K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "235414K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "242431K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 50, memory: "257114K" },
  { slide: 1, name: "memoryTest", timeToNextStep: 500, memory: "262114K" },
  { slide: 1, name: "beep", timeToNextStep: 500 },
  { slide: 1, name: "BIOS", timeToNextStep: 1500 },
  { slide: 1, name: "IDE 1", timeToNextStep: 1000 },
  { slide: 1, name: "IDE 1 ready", timeToNextStep: 100 },
  { slide: 1, name: "IDE 2", timeToNextStep: 1000 },
  { slide: 1, name: "IDE 2 ready", timeToNextStep: 100 },
  { slide: 1, name: "IDE 3", timeToNextStep: 1000 },
  { slide: 1, name: "IDE 3 ready", timeToNextStep: 100 },
  { slide: 1, name: "IDE 4", timeToNextStep: 300 },
  { slide: 1, name: "IDE 4 ready", timeToNextStep: 1000 },
  { slide: 2, name: "header", timeToNextStep: 100 },
  { slide: 2, name: "pci devices", timeToNextStep: 100 },
  { slide: 2, name: "boot", timeToNextStep: 1000 },
  { slide: 2, name: "boot2", timeToNextStep: 1500 },
  { slide: 3, name: "loading", timeToNextStep: 4000 },
];

const BootAnimation = ({ onFinish }) => {
  const [bootAnimationStep, setbootAnimationStep] = useState(0);
  const [buttonPressed, setButtonPressed] = useState(false);

  const [playSpinup] = useSound("/sfx/Boot/spinup.ogg", { volume: 1 });
  const [playBeep] = useSound("/sfx/Boot/beep.ogg", { volume: 1 });
  const [playReading1] = useSound("/sfx/Boot/reading1.ogg", { volume: 1 });
  const [playReading2] = useSound("/sfx/Boot/reading2.ogg", { volume: 1 });
  const [playReadShort1] = useSound("/sfx/readShort1.ogg", { volume: 0.5 });
  const [playReadShort2] = useSound("/sfx/readShort2.ogg", { volume: 0.5 });
  const { play: playBackground } = useBackgroundSound();

  useEffect(() => {
    if (buttonPressed && bootAnimationStep < bootAnimation.length) {
      if (bootAnimation[bootAnimationStep].name === "beep") playBeep();
      if (bootAnimation[bootAnimationStep].name === "IDE 2") playReading1();
      if (bootAnimation[bootAnimationStep].name === "IDE 4 ready")
        playReading2();
      if (bootAnimation[bootAnimationStep].name === "boot2") playReading2();
      setTimeout(() => {
        if (bootAnimationStep < bootAnimation.length - 1)
          setbootAnimationStep(bootAnimationStep + 1);
        else {
          playReadShort2();
          onFinish?.();
        }
      }, bootAnimation[bootAnimationStep].timeToNextStep);
    }
  }, [bootAnimationStep, buttonPressed, onFinish, playBeep, playReading1, playReading2, playReadShort1]);

  const isPastState = (name) =>
    bootAnimation.findIndex((step) => step.name === name) <= bootAnimationStep;

  const isCurrentState = (name) =>
    bootAnimation[bootAnimationStep].name === name;

  const isSlide = (slide) => bootAnimation[bootAnimationStep].slide === slide;

  const handlePressButton = () => {
    setButtonPressed(true);
    playSpinup();
    playBackground();
  };

  const renderCursor = () => <span className="cursor">_</span>;

  if (!buttonPressed)
    return (
      <div className="boot-container">
        <div className="on-button">
          <div className="center">
            <button onClick={handlePressButton}>
              <img src="img/on.svg" alt="ON button" />
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <div className="boot-container">
      {isSlide(1) && (
        <div className="slide1">
          <div>
            <div className="header">
              <div>Award Modular BIOS v6.0, An Energy Star Ally</div>
              <div>Copyright (C) 1984-98, Award Software, Inc.</div>
              <div>Version JE4333</div>
            </div>
            <div className="margin-top">PENTIUM ODP-MMX CPU at 200MHz</div>
            <div>
              {`Memory Test : ${
                isCurrentState("memoryTest")
                  ? bootAnimation[bootAnimationStep].memory
                  : "262114K"
              } OK`}
              {isCurrentState("memoryTest") && renderCursor()}
            </div>

            {isPastState("BIOS") && (
              <div className="margin-top">
                <div>Award Plug and Play BIOS Extension v1.0A</div>
                <div>Copyright (C) 1998, Award Software, Inc.{isCurrentState("BIOS") && renderCursor()}</div>
              </div>
            )}

            <div className="margin-top margin-left">
              {isPastState("IDE 1") && (
                <div>
                  Detecting IDE Primary Master ...{" "}
                  {isPastState("IDE 1 ready") && "ST3320620AS 3.AAE"}
                  {isCurrentState("IDE 1") && renderCursor()}
                </div>
              )}
              {isPastState("IDE 2") && (
                <div>
                  Detecting IDE Primary Slave ...{" "}
                  {isPastState("IDE 2 ready") && "WDC WD400BB-60JKA0 05.1C05"}
                  {isCurrentState("IDE 2") && renderCursor()}
                </div>
              )}
              {isPastState("IDE 3") && (
                <div>
                  Detecting IDE Secondary Master ...{" "}
                  {isPastState("IDE 3 ready") && "ATAPI CD-ROM"}
                  {isCurrentState("IDE 3") && renderCursor()}
                </div>
              )}
              {isPastState("IDE 4") && (
                <div>
                  Detecting IDE Secondary Slave ...{" "}
                  {isPastState("IDE 4 ready") && "None"}
                  {isCurrentState("IDE 4") && renderCursor()}
                </div>
              )}
            </div>
          </div>
          <div>
            <div>
              Press DEL to enter SETUP, Alt-F2 to enter EZ flash utility
            </div>
            <div>09/05/1998-1945-6A89HGO1C-00</div>
          </div>
          <img
            className="logo"
            src="img/energy-logo.jpg"
            alt="Epa Pollution Preventer"
          />
        </div>
      )}
      {isSlide(2) && (
        <div className="slide2">
          <div>
            {isPastState("header") && (
              <div className="frame">
                <div className="inner-frame">
                  <div className="column">
                    <div>CPU Type</div>
                    <div>Co-Processor</div>
                    <div>CPU Clock</div>
                  </div>
                  <div className="column">
                    <div>: PENTIUM ODP-MMX</div>
                    <div>: Installed</div>
                    <div>: 200MHz</div>
                  </div>
                  <div className="column">
                    <div>Base Memory</div>
                    <div>Extended Memory</div>
                    <div>Cache Memory</div>
                  </div>
                  <div className="column">
                    <div>: 640K</div>
                    <div>: 260048K</div>
                    <div>: None</div>
                  </div>
                </div>
                <div className="inner-frame">
                  <div className="column">
                    <div>Diskette Drive A</div>
                    <div>Diskette Drive B</div>
                    <div>Pri. Master Disk</div>
                    <div>Pri. Slave Disk</div>
                    <div>Sec. Master Disk</div>
                    <div>Sec. Slave Disk</div>
                  </div>
                  <div className="column">
                    <div>: 2.88M, 3.5 in.</div>
                    <div>: None</div>
                    <div>: ST3320620AS 3.AAE</div>
                    <div>: WDC WD400BB-60JKA0 05.1C05</div>
                    <div>: CDROM, Mode 4</div>
                    <div>: None</div>
                  </div>
                  <div className="column">
                    <div>Display Type</div>
                    <div>Serial Port(s)</div>
                    <div>Parallel Port(s)</div>
                    <div>EDO DRAM at Row(s)</div>
                    <div>SDRAM at Row(s)</div>
                    <div>L2 Cache Type</div>
                  </div>
                  <div className="column">
                    <div>: EGA/VGA</div>
                    <div>: 3F8 2F8</div>
                    <div>: 378</div>
                    <div>: None</div>
                    <div>: 0 1 2 3 4</div>
                    <div>: None</div>
                  </div>
                </div>
              </div>
            )}
            {isPastState("pci devices") && (
              <div className="devices-block">
                <div>PCI device listing.....</div>
                <div className="table">
                  <div className="column">
                    <div className="column-header">Bus No.</div>
                    <div>0</div>
                    <div>0</div>
                    <div>0</div>
                  </div>
                  <div className="column">
                    <div className="column-header">Device No.</div>
                    <div>7</div>
                    <div>17</div>
                    <div>18</div>
                  </div>
                  <div className="column">
                    <div className="column-header">Func No.</div>
                    <div>1</div>
                    <div>0</div>
                    <div>0</div>
                  </div>
                  <div className="column">
                    <div className="column-header">Vendor ID</div>
                    <div>8086</div>
                    <div>5333</div>
                    <div>121A</div>
                  </div>
                  <div className="column">
                    <div className="column-header">Device ID</div>
                    <div>1230</div>
                    <div>8A01</div>
                    <div>0002</div>
                  </div>
                  <div className="column">
                    <div className="column-header">Device Class</div>
                    <div>IDE Controller</div>
                    <div>Display Controller</div>
                    <div>Multimedia Device</div>
                  </div>
                  <div className="column">
                    <div className="column-header">IRQ</div>
                    <div>14</div>
                    <div>11</div>
                    <div>NA</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {isPastState("boot") && (
            <div>
              <div>Verifying DMI Pool Data .......</div>
              <div>Boot from ATAPI CD-ROM : {renderCursor()}</div>
            </div>
          )}
        </div>
      )}
      {isSlide(3) && (
        <div className="slide3 glitch-init">
          <div className="loading-container">
            <img src="img/scp.svg" alt="svg-logo" />
            <div className="text-content">
              <h1>SCP Foundation</h1>
              <Loading text="Loading..." fontSize={22} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BootAnimation;
