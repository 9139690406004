import React from "react";
import FrameLayout from "../Common/FrameLayout/FrameLayout";
import AnimatedText from "../Common/AnimatedText/AnimatedText";
import ImageLoader from "../Common/ImageLoader/ImageLoader";
import "./SideInfo.scss";

function SideInfo() {
  return (
    <div className="side-info glitch-init">
      <FrameLayout title="Subject ID: 34.534.317">
        <div className="image-row">
          <div className="image-block">
            <ImageLoader
              imgSrc={"img/perfil.jpg"}
              imgAlt={"Pablo's profile image"}
            />
          </div>
        </div>
        <div>
          <div className="info-group">
            <div className="label">
              {"///"} Name {"///"}
            </div>
            <div className="data">
              <AnimatedText initialWait={1200} text={"Pablo Miceli"} />
            </div>
          </div>
          <div className="info-group">
            <div className="label">
              {"///"} Birth date {"///"}
            </div>
            <div className="data">
              <AnimatedText initialWait={1400} text={"May 24th, 1989"} />
            </div>
          </div>
          <div className="info-group">
            <div className="label">
              {"///"} Location {"///"}
            </div>
            <div className="data">
              <AnimatedText initialWait={1800} text={"Argentina"} />
            </div>
          </div>
          <div className="info-group">
            <div className="label">
              {"///"} Occupation {"///"}
            </div>
            <div className="data">
              <AnimatedText initialWait={1600} text={"Software Engineer"} />
            </div>
          </div>
        </div>
      </FrameLayout>
    </div>
  );
}

export default SideInfo;
