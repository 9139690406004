export default function setIntervalTimes(callback, delay, repetitions, onFinish) {
    var x = 0;
    var intervalID = setInterval(() => {

       callback(x);

       if (++x === repetitions) {
           clearInterval(intervalID);
           if (onFinish) onFinish();
       }
    }, delay);
}