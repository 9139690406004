import React from "react";
import SubroutineLayout from "../../SubroutineLayout/SubroutineLayout";
import "./Experience.scss";

const Experience = () => {
  return (
    <SubroutineLayout title="Experience subroutine">
      <div className="experience-subroutine">
        <div className="experience">
          <h1>Mahisoft</h1>
          <div className="data">
            <span>Frontend developer</span>
            <span className="dates">Jul 2021 - Present</span>
          </div>
          <p>
            This represents the subject's second fully remote employment
            opportunity. Given the nature of his current employment with a
            staff augmentation company, the subject has had the opportunity to
            work on larger teams and diverse projects. This has enabled him to
            explore different types of architectures and approaches for
            developing complex frontend applications.
          </p>
        </div>
        <div className="experience">
          <h1>AutoServe1</h1>
          <div className="data">
            <span>Full stack developer</span>
            <span className="dates">May 2019 - Jun 2021</span>
          </div>
          <p>
            This represents the subject's first fully remote employment
            opportunity. As AutoServe1 is a product company, the subject's
            primary responsibilities involve maintaining and implementing new
            features in the company's application. The technological stack
            employed in this role included Node, Meteor, React, Docker, and AWS.
          </p>
        </div>
        <div className="experience">
          <h1>Intive-FDV</h1>
          <div className="data">
            <span>Frontend developer</span>
            <span className="dates">Mar 2019 - May 2019</span>
          </div>
          <p>
            This represents a relatively brief experience for the subject,
            during which he was part of a team of approximately 50
            individuals working on a large-scale React project. His
            responsibilities included writing maintainable code, developing new
            features, debugging, and maintaining excellent communication with
            both team members and clients. Unfortunately, due to long commuting
            times, the subject's involvement in the project was short-lived.
          </p>
        </div>
        <div className="experience">
          <h1>Celerative</h1>
          <div className="data">
            <span>Frontend developer</span>
            <span className="dates">Aug 2016 - Feb 2019</span>
          </div>
          <p>
            This represented a significant milestone in the subject's career.
            His primary role was as a frontend developer, working with a range
            of technologies such as Angular and React. However, he also gained
            experience as a full stack developer on various projects, utilizing
            technologies such as Node, GraphQL, MongoDB, and Docker.
            Additionally, the subject had the opportunity to lead teams of 2-3
            individuals, overseeing tasks such as client reporting, sprint
            planning, team communication, as well as programming. The
            technologies involved in these projects included Unity, HTC Vive,
            Oculus Rift, and Node.
          </p>
        </div>
        <div className="experience">
          <h1>Prospero Soft</h1>
          <div className="data">
            <span>Full stack developer</span>
            <span className="dates">Jan 2015 - Dec 2015</span>
          </div>
          <p>
            This represents the first significant employment opportunity for the
            subject. While employed in this role, he was able to deepen his
            understanding of the web stack and gain valuable experience working
            on medium-sized projects.
          </p>
        </div>
        <div className="experience">
          <h1>Freelance</h1>
          <div className="data">
            <span>Full stack developer</span>
            <span className="dates">Jan 2013 - Jan 2015</span>
          </div>
          <p>
            These initial stages marked the beginning of the subject's web
            development career. He worked on occasional small-scale projects,
            gradually familiarizing himself with various web technologies and
            gaining proficiency in the web stack. The majority of the projects
            were developed using PHP, MySQL, HTML, CSS, and JavaScript.{" "}
          </p>
        </div>
      </div>
    </SubroutineLayout>
  );
};

export default Experience;
