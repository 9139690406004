import React from "react";
import SubroutineLayout from "../../SubroutineLayout/SubroutineLayout";
import "./Contact.scss";

const Contact = () => {
  return (
    <SubroutineLayout title="Contact subroutine">
      <div className="contact">
        <div className="section">
          <p>
            Based on our analysis, email appears to be the most effective means
            of contacting the subject. However, if you are considering offering
            him a job, please PROCEED WITH CAUTION. It appears that the subject
            may display hostile behavior towards job offers related to PHP
            development.
          </p>
          <div className="contact-method">
            {">> "}Email address:{" "}
            <span className="email">pablomiceli24@gmail.com</span>
          </div>
        </div>

        <div className="section">
          If you are interested in observing the subject's behavior related to
          his constant search for social validation and occasional tendencies
          towards narcissism, we recommend analyzing his social media
          presence.
          <div className="contact-method">
            {">> "}Instagram:{" "}
            <a
              target="_Blank"
              rel="noreferrer"
              href="https://www.instagram.com/pablomiceli24/"
            >
              https://www.instagram.com/pablomiceli24/
            </a>
          </div>
          <div className="contact-method">
            {">> "}Twitter:{" "}
            <a
              target="_Blank"
              rel="noreferrer"
              href="https://twitter.com/pablomiceli24"
            >
              https://twitter.com/pablomiceli24
            </a>
          </div>
          <div className="contact-method">
            {">> "}LinkedIn:{" "}
            <a
              target="_Blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/pablo-miceli-17002341/"
            >
              https://www.linkedin.com/in/pablo-miceli-17002341/
            </a>
          </div>
        </div>
      </div>
    </SubroutineLayout>
  );
};

export default Contact;
