import React, { useState, useEffect } from 'react'
import SetIntervalTimes from '../../../Utils/SetIntervalTimes'

const AnimatedText = ({ text, initialWait, animationTime }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            SetIntervalTimes(rep => {
                setIndex(rep + 1);
            }, animationTime / text.length, text.length);
        }, initialWait)
    }, [animationTime, initialWait, text.length])

    if (index > 0) {
        return <span>{text.slice(0, index)}</span>
    }
    return <span style={{opacity: 0}}>{text.slice(0, 1)}</span>
}

AnimatedText.defaultProps = {
    animationTime: 500,
    initialWait: 0,
    text: " "
}

export default AnimatedText;