import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ActiveSubroutineContext = React.createContext();

function ActiveSubroutineProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSubroutine, setActiveSubroutineContext] = React.useState({
    name:
      location.pathname === "/"
        ? null
        : location.pathname.charAt(1).toUpperCase() +
          location.pathname.slice(2).toLowerCase(),
    fromCLI: false,
  });
  const [previousSubroutine, setPreviousSubroutine] = React.useState({
    name: null,
    fromCLI: false,
  });
  const setActiveSubroutine = (subroutine) => {
    if (subroutine.name !== activeSubroutine.name) {
      setPreviousSubroutine(activeSubroutine);
      setActiveSubroutineContext(subroutine);
      if (subroutine.name) {
        navigate(`/${subroutine.name.toLowerCase()}`);
      } else {
        navigate("/");
      }
    }
  };
  return (
    <ActiveSubroutineContext.Provider
      value={{ previousSubroutine, activeSubroutine, setActiveSubroutine }}
    >
      {children}
    </ActiveSubroutineContext.Provider>
  );
}

function useActiveSubroutine() {
  const context = React.useContext(ActiveSubroutineContext);
  if (context === undefined) {
    throw new Error(
      "useActiveSubroutine must be used within a ActiveSubroutineProvider"
    );
  }
  return context;
}

export { ActiveSubroutineProvider, useActiveSubroutine };
