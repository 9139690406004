import React from "react";
import { useActiveSubroutine } from "../../Contexts/ActiveSubroutine";
import FrameLayout from "../Common/FrameLayout/FrameLayout";
import "./VisualInterface.scss";

const routes = [
  { name: "Subject", route: "/subject" },
  { name: "Experience", route: "/experience" },
  { name: "Education", route: "/education" },
  { name: "Projects", route: "/projects" },
  { name: "Contact", route: "/contact" },
];

const VisualInterface = () => {
  const { activeSubroutine, setActiveSubroutine } = useActiveSubroutine();

  const clickHandler = (route) => () => {
    setActiveSubroutine({ name: route.name, fromCLI: false });
  };

  return (
    <div className="visual-interface glitch-init">
      <FrameLayout title="Visual interface">
        <ul>
          {routes.map((route) => (
            <li
              key={route.name}
              className={
                activeSubroutine && activeSubroutine.name === route.name
                  ? "active glitch-init-fast"
                  : ""
              }
              onClick={clickHandler(route)}
            >
              {route.name}
              <div className="sub-text">Subroutine</div>
            </li>
          ))}
        </ul>
      </FrameLayout>
    </div>
  );
};

export default VisualInterface;
