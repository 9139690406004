import React, { useEffect, useState } from "react";
import AudioSpectrum from "react-audio-spectrum";
import "./AudioPlayer.scss";

const AudioPlayer = ({ src, id, title }) => {
  const [playing, setPlaying] = useState(false);

  const togglePlayer = () => setPlaying(!playing);

  useEffect(() => {
    const stopHandler = () => setPlaying(false);
    const audioElement = document.getElementById(id);
    audioElement.addEventListener("ended", stopHandler);

    if (playing) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0;
    }

    return () => {
      audioElement.removeEventListener("ended", stopHandler);
    };
  }, [playing, id]);

  return (
    <div className="audio-player">
      <div className="audio-player-container">
        <div className="file-name">{title}</div>
        <div className="audio-player-content">
          <div className="audio-player-controls">
            <div className="play-button-outer" onClick={togglePlayer}>
              {!playing ? (
                <div className="button play"></div>
              ) : (
                <div className="button stop"></div>
              )}
            </div>
          </div>

          <div className="spectrum">
            <audio id={id} src={src} />
            <AudioSpectrum
              id={`audio-canvas-${id}`}
              height={45}
              width={150}
              audioId={id}
              capColor={"#6473FF"}
              capHeight={2}
              meterWidth={3}
              meterCount={256}
              meterColor={[
                { stop: 0, color: "#6473FF" },
                { stop: 0.5, color: "#6473FF" },
                { stop: 1, color: "#6473FF" },
              ]}
              gap={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
