import React, { useState } from "react";
import SideInfo from "../SideInfo/SideInfo";
import VisualInterface from "../VisualInterface/VisualInterface";
import CommandLineInterface from "../CommandLineInterface/CommandLineInterface";
import SubroutinesRouter from "../SubroutinesRouter/SubroutinesRouter";
import BootAnimation from "../BootAnimation/BootAnimation";
import { useBackgroundSound } from "../../Contexts/BackgroundSound";
import Sound from "react-sound-2";
import "./App.scss";
import "./Scanlines.scss";

function App() {
  const [showBooting, setShowBooting] = useState(true);
  const { playing: backgroundPlaying } = useBackgroundSound();

  const handleBootEnd = () => {
    setShowBooting(false);
  };

  return (
    <div className="scanlines">
      <Sound
        loop
        url="/sfx/Boot/background.ogg"
        playStatus={
          backgroundPlaying ? Sound.status.PLAYING : Sound.status.STOPPED
        }
      />
      {showBooting ? (
        <BootAnimation onFinish={handleBootEnd} />
      ) : (
        <div className="layout">
          <aside className="layout-aside">
            <SideInfo />
          </aside>
          <div className="site-content">
            <VisualInterface />
            <CommandLineInterface />
            <div className="margin-t-15">
              <SubroutinesRouter />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
