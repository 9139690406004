import React from "react";
import Subject from "../Subroutines/Subject/Subject";
import Experience from "../Subroutines/Experience/Experience";
import Education from "../Subroutines/Education/Education";
import Projects from "../Subroutines/Projects/Projects";
import Contact from "../Subroutines/Contact/Contact";

import { createBrowserRouter, RouterProvider, Routes, Route  } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/subject",
    element: <Subject />,
  },
  {
    path: "/experience",
    element: <Experience />,
  },
  {
    path: "/education",
    element: <Education />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

const SubroutinesRouter = () => {
  return (
    <Routes>
      <Route exact path="/" />
      <Route exact path="/subject" element={<Subject />}/>
      <Route exact path="/experience" element={<Experience />}/>
      <Route exact path="/education" element={<Education />}/>
      <Route exact path="/projects" element={<Projects />}/>
      <Route exact path="/contact" element={<Contact />}/>
    </Routes>
  );
};

export default SubroutinesRouter;
