import React, { useState } from "react";
import useSound from "use-sound";
import { isMobile } from "react-device-detect";
import SubroutineLayout from "../../SubroutineLayout/SubroutineLayout";
import ImageView from "../../Common/ImageView/ImageView";
import "./Projects.scss";

const projects = [
  {
    name: "AstroCódigo",
    shortDescription: "A serious game to learn basic programming concepts.",
    previewImage: "img/projects/astrocodigoPreview.PNG",
    description:
      "AstroCódigo (or AstroCode) is a serious game designed to teach fundamental programming concepts. The subject, along with a friend, developed this game as an end-of-grade project. It is currently available in both Spanish and English, and can be played both online and offline.",
    media: {
      type: "youtube",
      path: "https://www.youtube.com/embed/4TRj6M160Ko",
    },
    status: "Finished",
    links: [
      { name: "Thesis", link: "http://sedici.unlp.edu.ar/handle/10915/61204" },
      { name: "Website", link: "http://astrocodigo.com/" },
    ],
  },
  {
    name: "React Tetris",
    shortDescription: "A tetris game made in React.",
    previewImage: "img/projects/reactTetrisPreview.PNG",
    description:
      'This is the classic tetris game but made using React. The project was developed as a way to practice TypeScript and the NextJS framework. The game follows some of the original game guidelines including wall-kicks, tetris clears, and the "SRS" rotation system.',
    media: {
      type: "iframe",
      title: "Or play it here!",
      path: "https://react-tetris-alpha.vercel.app/",
      mobileFallback: "img/projects/reactTetris.gif",
    },
    status: "Finished",
    links: [
      {
        name: "Play it in a new tab (Desktop only)",
        link: "https://react-tetris-alpha.vercel.app/",
      },
      {
        name: "Github repository",
        link: "https://github.com/pablonm/react-tetris",
      },
    ],
  },
  {
    name: "Treat",
    shortDescription: "A graphic adventure game.",
    previewImage: "img/projects/treatPreview.jpg",
    description:
      'This is a graphic adventure game developed using the Unity engine. The team behind the game comprised an illustrator, a musician, and the subject, who served as the programmer. The team initially met during a global game jam and later decided to collaborate on a larger-scale project.\nTheir efforts resulted in a demo version of the game, which received classification in the "My First Published Game" program, administered by the Argentinian Videogame Developers Association.',
    media: { type: "image", path: "img/projects/treat.gif" },
    status: "Abandoned",
    links: [
      {
        name: "Instagram profile",
        link: "https://www.instagram.com/treat_game/",
      },
      {
        name: "Playable demo (windows)",
        link: "https://drive.google.com/file/d/1yA9_Fpr7vP11KO2KjlwZ9zFtGhVLu6Dp/view?usp=sharing",
        download: true,
      },
    ],
  },
  {
    name: "Shitty Moba",
    shortDescription: "The next league of legends.",
    previewImage: "img/projects/shittyMobaPreview.PNG",
    description:
      "This game is only a few bug fixes away from becoming the next Dota or League of Legends. It was developed using Unity, with networking implemented through Photon.\nTo play the game, users need to enter a username and a room name. Other players must enter the same room name to join and play together.",
    media: { type: "image", path: "img/projects/shittyMoba.gif" },
    status: "Finished. WARNING: Bugs found.",
    links: [
      { name: "Play it in the browser", link: "https://shittymoba.github.io/" },
      {
        name: "Github repository",
        link: "https://github.com/pablonm/ShittyMoba",
      },
    ],
  },
  {
    name: "Moon Lander AI",
    shortDescription: "A ship that lands by itself using AI.",
    previewImage: "img/projects/moonlanderPreview.PNG",
    description:
      "This is a small Unity project that utilizes the ML Agents package to train a moonlander to autonomously land on a small platform.",
    media: { type: "image", path: "img/projects/moonlander.gif" },
    status: "Finished",
    links: [
      {
        name: "Github repository",
        link: "https://github.com/pablonm/moon-lander-unity-mlagents",
      },
    ],
  },

  {
    name: "Tunnel Ship AI",
    shortDescription: "An AI that flies a ship through a tunnel.",
    previewImage: "img/projects/tunnelShipPreview.PNG",
    description:
      "This project aimed to determine if a genetic algorithm could effectively optimize the parameters of a neural network to enable a ship to navigate through a procedurally generated tunnel. The experiment was successful, as the ship was able to navigate through the tunnel with the optimized parameters. The project was developed using Unity.",
    media: {
      type: "youtube",
      path: "https://www.youtube.com/embed/ljm8Z7PWfTE",
    },
    status: "Finished",
    links: [
      {
        name: "Github repository",
        link: "https://github.com/pablonm/tunnel-ship-ai",
      },
    ],
  },
  {
    name: "Viborracha",
    shortDescription: "The snake game but with AI.",
    previewImage: "img/projects/viborrachaPreview.PNG",
    description:
      "This represents the subject's initial foray into Python programming, genetic algorithms, and neural networks. The project in question involves a \"drunk\" snake that moves autonomously and attempts to consume as much as possible before inevitably perishing. It's a rather poignant tale, in its own way.",
    media: { type: "image", path: "img/projects/viborracha.gif" },
    status: "Finished",
    links: [
      {
        name: "Github repository",
        link: "https://github.com/pablonm/viborracha",
      },
    ],
  },
  {
    name: "Disturbing Game",
    shortDescription: "A very disturbing graphic adventure game.",
    previewImage: "img/projects/juegoTurbioPreview.PNG",
    description:
      "This is a Unity-based graphic adventure game that features a deeply unsettling storyline. This is proof that the subject doesn't have drawing skills.",
    media: { type: "image", path: "img/projects/juegoTurbio.gif" },
    status: "Abandoned",
    links: [],
  },

  {
    name: "Cursed Loot",
    shortDescription: "A mobile game about collecting objects.",
    previewImage: "img/projects/cursedLootPreview.jpg",
    description:
      "This mobile game, developed using Unity, revolves around a daily chest that provides the player with collectible objects. Each object corresponds to a unique story, and the player is tasked with collecting all of the objects in order to assemble the complete narrative within the same universe.",
    media: { type: "image", path: "img/projects/cursedLootPreview.jpg" },
    status: "Abandoned",
    links: [],
  },
  {
    name: "Code Reforge",
    shortDescription: "A web platform for code refactoring.",
    previewImage: "img/projects/codeReforgePreview.PNG",
    description:
      "This web platform allows users to register and upload code snippets for refactoring, or create and submit refactorings for existing snippets. The primary objective of the project was to enable the subject to learn Next.js, although he ultimately lost motivation and failed to continue development.",
    media: { type: "image", path: "img/projects/codeReforgePreview.PNG" },
    status: "Kind of finished? but also abandoned.",
    links: [{ name: "Live website", link: "https://codereforge.com/" }],
  },
  {
    name: "<Corrupted data>",
    shortDescription: "Â£©▀‡я√ráÚÆ  ‡я√ráÚÆ",
    previewImage: "img/projects/corrupted.gif",
    description: `Â£©▀‡я√ráÚÆ  ‡я√ráÚÆ 舐▀ 舐—Â£© 舐—▀ ©▀53 ©▀æØ¢Ã—óÞ¢áñ ©▀345 ▀æØ¢Ãâ€š�óÞ¢áñráÚÆ 舐 ráÚÆ  —345óÞ¢áñ6 â€š�óÞ¢áñ▀æØ¢Ãâ€š� —舐 óÞ¢áñ— 舐æØ¢Ã 舐—5▀©▀æØ¢Ã345 ñŠÞ¥¿舐áÍÞæ— ráÚÆ 舐— 345—舐æØ¢Ã ▀æØ¢Ã óÞ¢áñæØ¢Ã â€š�▀æØ¢Ã 舐— â€š�æØ¢Ãâ€š�舐 æØ¢Ã—óÞ¢áñáÍÞæ æØ¢Ãâ€š�▀æØ¢Ã舐óÞ¢áñ —â€š�áÍÞæ —æØ¢Ã舐 â€š�â€š�áÍÞæ 舐æØ¢ÃóÞ¢áñ½	áÍÞæ`,
    media: { type: null, path: null },
    status: "-",
    links: [],
  },
];

const Projects = () => {
  const [currentProject, setCurrentProject] = useState(null);
  const [playSound] = useSound("/sfx/readShort2.ogg", { volume: 0.5 });

  const viewProject = (project) => () => {
    playSound();
    setCurrentProject(project);
  };
  const goBack = () => setCurrentProject(null);

  return (
    <SubroutineLayout title="Projects subroutine">
      <div className="projects">
        {!currentProject ? (
          <div>
            <div className="intro">
              The subject seems to enjoy working on little side project in it's
              free time. These are some of them.
            </div>

            <div className="projects-grid">
              {projects.map((project) => {
                return (
                  <div className="project-preview" key={project.name}>
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(${project.previewImage})`,
                      }}
                    />
                    <h1>
                      {"///"} {project.name} {"///"}
                    </h1>
                    <div className="project-description">
                      <p>{project.shortDescription}</p>
                      <div className="action">
                        <button onClick={viewProject(project)}>
                          Read more {">>"}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="project-view glitch-init">
            <div class="go-back">
              <button onClick={goBack}>{"<<"} Go back</button>
            </div>
            <h1>{currentProject.name}</h1>
            <div className="project-content">
              {(currentProject.media.type !== "iframe" || (isMobile && currentProject.media.mobileFallback)) && (
                <div className="image">
                  {currentProject.media.type === "youtube" && (
                    <iframe
                      width="100%"
                      height="315"
                      src={currentProject.media.path}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                  {currentProject.media.type === "image" && (
                    <ImageView
                      src={currentProject.media.path}
                      alt={currentProject.name}
                    />
                  )}
                  {currentProject.media.type === "iframe" && (
                    <ImageView
                      src={currentProject.media.mobileFallback}
                      alt={currentProject.name}
                    />
                  )}
                </div>
              )}
              <div className="content">
                <div className="description">
                  {currentProject.description.split("\n").map((line) => (
                    <p>{line}</p>
                  ))}
                </div>
                <div className="status">Status: {currentProject.status}</div>
                <div className="links">
                  {currentProject.links.map((link) => (
                    <a
                      key={link.link}
                      href={link.link}
                      target="_Blank"
                      rel="noreferrer"
                      download={link.download}
                    >
                      {">> "}
                      {link.name}
                    </a>
                  ))}
                </div>
                {(currentProject.media.type === "iframe" && currentProject.media.mobileFallback && !isMobile) && (
                  <div className="iframe">
                    <h2>{currentProject.media.title}</h2>
                    <iframe
                      src={currentProject.media.path}
                      title="React Tetris"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </SubroutineLayout>
  );
};

export default Projects;
