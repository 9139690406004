import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App/App";
import { BrowserRouter } from "react-router-dom";
import "./reset.scss";
import "./common.scss";

import { ActiveSubroutineProvider } from "./Contexts/ActiveSubroutine";
import { BackgroundSoundProvider } from "./Contexts/BackgroundSound";

const documentHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", documentHeight);
documentHeight();

const container = document.getElementById("root");

const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ActiveSubroutineProvider>
        <BackgroundSoundProvider>
          <App />
        </BackgroundSoundProvider>
      </ActiveSubroutineProvider>
    </BrowserRouter>
  </React.StrictMode>
);
