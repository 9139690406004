import React from 'react';
import './ImageLoader.scss'

const ImageLoader = ({ loadingTime, imgSrc, imgAlt, bars }) => {

    return (
        <div className="image-loader">
            <img src={imgSrc} alt={imgAlt}></img>
            <div className="loading-bars">
                {Array(bars).fill(0).map((elem, index) =>
                    <div key={`bar${index}`} 
                        className="bar" style={{
                        animationName: "load",
                        animationDuration: `${loadingTime / bars / 1000}s`,
                        animationDelay: `${loadingTime / bars * index / 1000}s`,
                        animationFillMode: "forwards",
                        animationTimingFunction: "linear"
                    }} />
                )}
            </div>
        </div>
    )
}

ImageLoader.defaultProps = {
    imgAlt: 'Some image',
    loadingTime: 3000,
    bars: 10
}

export default ImageLoader;