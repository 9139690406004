import React from 'react'
import SubroutineLayout from '../../SubroutineLayout/SubroutineLayout'
import ImageView from '../../Common/ImageView/ImageView'

import './Education.scss'

const epicReactImages = ["img/epic1.PNG", "img/epic2.PNG", "img/epic3.PNG"]

const Education = () => {
    return (
        <SubroutineLayout title="Education subroutine">
            <div className="education-subroutine">
                <div className="education">
                    <h1>Bachelor's degree in Computer Science</h1>
                    <div className="data">
                        <span>La Plata National University</span>
                        <span className="dates">2007 - 2017</span>
                    </div>
                    <div className="images">
                        <div className="image"><ImageView src="img/degree.jpg" alt="Degree image" /></div>
                        <div className="clear-fix only-mobile-big" />
                    </div>
                </div>

                <div className="education">
                    <h1>Epic React Course</h1>
                    <div className="data">
                        <span>Kent C. Dodds</span>
                        <span className="dates">2021</span>
                    </div>
                    <div className="images">
                        <div className="image"><ImageView srcArray={epicReactImages} imageIndex={0} alt="Degree image" /></div>
                        <div className="image"><ImageView srcArray={epicReactImages} imageIndex={1} alt="Degree image" /></div>
                        <div className="image"><ImageView srcArray={epicReactImages} imageIndex={2} alt="Degree image" /></div>
                        <div className="clear-fix only-mobile-big" />
                    </div>
                </div>

            </div>
        </SubroutineLayout>
    )
}

export default Education