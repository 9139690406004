import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import "./ImageView.scss";

const ImageView = ({ src, srcArray, alt, imageIndex }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = src ? [src] : srcArray;

  const onClose = () => setIsViewerOpen(false);
  const onOpen = () => setIsViewerOpen(true);

  return (
    <div className="image-view">
      <img src={images[imageIndex]} alt={alt} onClick={onOpen} />
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={imageIndex}
          onClose={onClose}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
        />
      )}
    </div>
  );
};

ImageView.defaultProps = {
  imageIndex: 0,
};

export default ImageView;
