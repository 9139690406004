import React, { useState, useRef, useEffect } from "react";
import FrameLayout from "../Common/FrameLayout/FrameLayout";
import classNames from "classnames";
import { useActiveSubroutine } from "../../Contexts/ActiveSubroutine";
import setIntervalTimes from "../../Utils/SetIntervalTimes";
import "./CommandLineInterface.scss";

const CommandLineInterface = () => {
  const [lines, setLines] = useState([
    {
      type: "output",
      isAsciiArt: true,
      text: ` _____ _____ ______   _____ _____ 
/  ___/  __ \\| ___ \\ |  _  /  ___|
\\ \`--.| /  \\/| |_/ / | | | \\ \`--. 
 \`--. \\ |    |  __/  | | | |\`--. \\
/\\__/ / \\__/\\| |     \\ \\_/ /\\__/ /
\\____/ \\____/\\_|      \\___/\\____/    V 1.89 
\n`,
    },
    { type: "output", text: 'Welcome. Type "help" to see available commands.' },
  ]);
  const inputRef = useRef();
  const { previousSubroutine, activeSubroutine, setActiveSubroutine } =
    useActiveSubroutine();

  useEffect(() => {
    if (activeSubroutine.name && !activeSubroutine.fromCLI) {
      const animationTime = 200;
      const commandToRun = `run ${activeSubroutine.name}`;
      setIntervalTimes(
        (index) => {
          inputRef.current.value = commandToRun.slice(0, index);
        },
        animationTime / commandToRun.length,
        commandToRun.length + 1,
        () => setTimeout(() => execute(commandToRun, true), 100)
      );
    }
    if (
      !activeSubroutine.name &&
      !activeSubroutine.fromCLI &&
      previousSubroutine.name
    ) {
      const animationTime = 100;
      const commandToRun = `kill`;
      setIntervalTimes(
        (index) => {
          inputRef.current.value = commandToRun.slice(0, index);
        },
        animationTime / commandToRun.length,
        commandToRun.length + 1,
        () => setTimeout(() => execute(commandToRun, true), 100)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    previousSubroutine.name,
    activeSubroutine.name,
    activeSubroutine.fromCLI,
  ]);

  const commands = [
    {
      name: "run",
      args: ["subject", "experience", "education", "projects", "contact"],
      run: ({ arg, textEntered, onlyOutput }) => {
        const subroutine =
          arg.charAt(0).toUpperCase() + arg.slice(1).toLowerCase();
        if (subroutine === activeSubroutine.name && !onlyOutput) {
          setLines([
            ...lines,
            { type: "command", text: textEntered },
            {
              type: "output",
              text: `Subroutine ${subroutine} is already running.`,
            },
          ]);
          return;
        }
        if (!onlyOutput) {
          setActiveSubroutine({ name: subroutine, fromCLI: true });
        }
        setLines([
          ...lines,
          { type: "command", text: textEntered },
          { type: "output", text: `Starting ${subroutine} subroutine.` },
        ]);
      },
    },
    {
      name: "kill",
      run: ({ textEntered, onlyOutput }) => {
        setLines([
          ...lines,
          { type: "command", text: textEntered },
          {
            type: "output",
            text: `Terminating ${
              !activeSubroutine.name && !activeSubroutine.fromCLI
                ? previousSubroutine.name
                : activeSubroutine.name
            } subroutine.`,
          },
        ]);
        if (!onlyOutput) {
          setActiveSubroutine({ name: null, fromCLI: true });
        }
      },
    },
    {
      name: "help",
      run: ({ textEntered }) => {
        setLines([
          ...lines,
          { type: "command", text: textEntered },
          {
            type: "output",
            text: 'Available commands: "run <subroutine>", "kill", "help", "clear".',
          },
        ]);
      },
    },
    {
      name: "clear",
      run: () => setLines([]),
    },
  ];

  const execute = (textEntered, onlyOutput) => {
    const commandEntered = textEntered.split(" ")[0];
    const argEntered = textEntered.split(" ")[1];
    const newLinesWithCommand = [
      ...lines,
      { type: "command", text: textEntered },
    ];

    const commandToExecute = commands.find(
      (c) => c.name === commandEntered.toLowerCase()
    );
    if (!commandToExecute) {
      setLines([
        ...newLinesWithCommand,
        {
          type: "output",
          text: `Command "${commandEntered}" not recognized. Type "help" to see the list of available commands.`,
        },
      ]);
    } else {
      if (
        argEntered &&
        commandToExecute.args &&
        commandToExecute.args.includes(argEntered.toLowerCase())
      ) {
        commandToExecute.run({
          arg: argEntered.toLowerCase(),
          textEntered,
          onlyOutput,
        });
      }
      if (
        argEntered &&
        commandToExecute.args &&
        !commandToExecute.args.includes(argEntered.toLowerCase())
      ) {
        if (commandToExecute.args) {
          setLines([
            ...newLinesWithCommand,
            {
              type: "output",
              text: `Command "${commandEntered}" doesn't accept "${argEntered}" argument. Try one of the following: ${commandToExecute.args
                .map((a) => `"${a}"`)
                .join(", ")}.`,
            },
          ]);
        } else {
          setLines([
            ...newLinesWithCommand,
            {
              type: "output",
              text: `Command "${commandEntered}" doesn't accept "${argEntered}" argument.`,
            },
          ]);
        }
      }
      if (!argEntered && !commandToExecute.args) {
        commandToExecute.run({ textEntered, onlyOutput });
      }
    }

    inputRef.current.value = null;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      execute(inputRef.current.value);
    }
  };

  const focusInput = () => inputRef.current.focus();

  return (
    <div id="cli" className="command-line-interface glitch-init">
      <FrameLayout title="Command line interface">
        <div className="commands-box" onClick={focusInput}>
          <div className="executed-commands">
            <ul>
              {lines.map((line, index) => (
                <li className={classNames({ ascii: line.isAsciiArt })} key={`${line.text}-${index}`}>
                  {line.type === "command" ? ">> " : ""}
                  {line.text}
                </li>
              ))}
            </ul>
          </div>
          <div className="new-command">
            <span>{">>"}</span>
            <input type="text" onKeyPress={handleKeyPress} ref={inputRef} />
          </div>
        </div>
      </FrameLayout>
    </div>
  );
};

export default CommandLineInterface;
