import React from "react";
import "./FrameLayout.scss";

const FrameLayout = ({ title, children, showClose, onClose }) => {
  return (
    <div className="frame-layout-container">
      <div className="title">
        <div className="filler">
          {"////////////////////////////////////////////////////////////////////////////////"}
        </div>
        <div className="title-text">{title}</div>
        <div className="filler">
          {"////////////////////////////////////////////////////////////////////////////////"}
        </div>
        {showClose && <div className="button-container"><button onClick={onClose}>X</button></div>}
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default FrameLayout;
